import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const useRouteChange = () => {
  const router = useRouter();
  const [routingStarted, setRoutingStarted] = useState(false);
  const [longWaitTime, setLongWaitTime] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setRoutingStarted(true);
      setLongWaitTime(false);

      timeoutRef.current = setTimeout(() => {
        setLongWaitTime(true);
      }, 5000);
    };

    const handleRouteChangeComplete = () => {
      setRoutingStarted(false);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [router]);

  return { routingStarted, longWaitTime };
};
