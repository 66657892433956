import React, { FC } from 'react';
import { useRouteChange } from '@/lib/hooks/routing';
import PageLoading from '@/componentsV2/loading/PageLoading';

interface RoutingLoaderLayout {
  children?: any;
}

const RoutingLoaderLayout: FC = ({ children }) => {
  const { routingStarted, longWaitTime } = useRouteChange();

  return (
      <>
        <PageLoading open={routingStarted} longWaitTime={longWaitTime} />
        {children}
      </>
  );
};

export default RoutingLoaderLayout;