import React, { FC } from 'react';
import { Stack, ThemeProvider, createTheme, Backdrop, styled } from '@mui/material';
import ProgressSpinner from '@/components/ProgressSpinner';
import { Paragraph } from '@/theme/text-variants';
import { colorTokens } from '@/theme/color-tokens';
import { colors } from '../../theme/colors';

const theme = createTheme({});

interface PageLoadingProps {
  open: boolean;
  longWaitTime?: boolean;
}

const PageLoading: FC<PageLoadingProps> = ({ longWaitTime, open=false }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledBackdrop open={open}>
      <Stack height="100vh" justifyContent="center" alignItems="center" gap="24px">
        <ProgressSpinner size="64px" color={colorTokens.iconPrimary} />
        <Stack gap="8px" alignItems="center" justifyContent="center">
          <Paragraph size="lg" color={colorTokens.iconPrimary} fontWeight="bold">
            Loading...
          </Paragraph>
          {longWaitTime && (
            <Paragraph size="sm" color={colorTokens.iconPrimary} fontWeight="bold">
              Taking longer than usual...we appreciate your patience.
            </Paragraph>
          )}
        </Stack>
      </Stack>
      </StyledBackdrop>
    </ThemeProvider>
  );
};

export default PageLoading;

const StyledBackdrop = styled(Backdrop)(() => ({
  zIndex: '5000',
  background: colors.white
}));    