import { colorTokens } from './color-tokens';
import { Typography, styled } from '@mui/material';

export type fontWeight = 'regular' | 'medium' | 'semibold' | 'bold';
export type headingVariant = '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
export type textVariant = '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xs';
export type overlineVariant = 'md' | 'sm' | 'xs';

const fontWeights = {
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700'
};

const Poppins = styled(Typography)`
  font-family: Poppins;
`;

const Inter = styled(Typography)`
  font-family: Inter;
`;

interface Sizes {
  fontSize?: string;
  lineHeight?: string;
  fontSizeMobile?: string;
  lineHeightMobile?: string;
  letterSpacing?: string;
}

const headingSizes: Record<headingVariant, Sizes> = {
  '4xl': { fontSize: '72px', lineHeight: '90px', fontSizeMobile: '60px', lineHeightMobile: '75px' },
  '3xl': { fontSize: '60px', lineHeight: '75px', fontSizeMobile: '48px', lineHeightMobile: '60px' },
  '2xl': { fontSize: '48px', lineHeight: '60px', fontSizeMobile: '36px', lineHeightMobile: '45px' },
  xl: { fontSize: '36px', lineHeight: '45px', fontSizeMobile: '30px', lineHeightMobile: '37.5px' },
  lg: { fontSize: '30px', lineHeight: '37.5px', fontSizeMobile: '24px', lineHeightMobile: '30px' },
  md: { fontSize: '25px', lineHeight: '20px', fontSizeMobile: '20px', lineHeightMobile: '20px' },
  sm: { fontSize: '20px', lineHeight: '20px', fontSizeMobile: '16px', lineHeightMobile: '17.5px' },
  xs: { fontSize: '17.5px', lineHeight: '17.5px', fontSizeMobile: '14px', lineHeightMobile: '15px' }
};

const textSizes: Record<textVariant, Sizes> = {
  '4xl': { fontSize: '36px', lineHeight: '54px', fontSizeMobile: '30px', lineHeightMobile: '45px' },
  '3xl': { fontSize: '30px', lineHeight: '45px', fontSizeMobile: '24px', lineHeightMobile: '36px' },
  '2xl': { fontSize: '24px', lineHeight: '36px', fontSizeMobile: '24px', lineHeightMobile: '36px' },
  xl: { fontSize: '20px', lineHeight: '30px', fontSizeMobile: '20px', lineHeightMobile: '30px' },
  lg: { fontSize: '18px', lineHeight: '27px', fontSizeMobile: '18px', lineHeightMobile: '27px' },
  md: { fontSize: '16px', lineHeight: '24px', fontSizeMobile: '16px', lineHeightMobile: '24px' },
  sm: { fontSize: '14px', lineHeight: '21px', fontSizeMobile: '14px', lineHeightMobile: '21px' },
  xs: { fontSize: '12px', lineHeight: '18px', fontSizeMobile: '12px', lineHeightMobile: '18px' },
  '2xs': { fontSize: '10px', lineHeight: '15px', fontSizeMobile: '10px', lineHeightMobile: '15px' }
};

const overlineSizes: Record<overlineVariant, Sizes> = {
  md: { fontSize: '16px', lineHeight: '24px', fontSizeMobile: '16px', lineHeightMobile: '24px' },
  sm: { fontSize: '14px', lineHeight: '24px', fontSizeMobile: '12px', lineHeightMobile: '18px' },
  xs: { fontSize: '12px', lineHeight: '24px', fontSizeMobile: '10px', lineHeightMobile: '15px' }
};

const iconSizes: Record<overlineVariant, Sizes> = {
  md: { fontSize: '16px', lineHeight: '24px', fontSizeMobile: '16px', lineHeightMobile: '24px' },
  sm: { fontSize: '14px', lineHeight: '24px', fontSizeMobile: '12px', lineHeightMobile: '18px' },
  xs: { fontSize: '12px', lineHeight: '24px', fontSizeMobile: '10px', lineHeightMobile: '15px', letterSpacing: '0.7px' }
};

export const Heading = styled(Poppins)<{
  size: headingVariant;
  color?: string;
  fontWeight?: fontWeight;
  component?: string;
}>`
  color: ${({ color }) => (color ? color : colorTokens.textDefault)};
  font-weight: ${({ fontWeight }) => fontWeights[(fontWeight || 'semibold') as fontWeight]};
  font-size: ${({ size }) => (headingSizes[size] as { fontSize?: string })?.fontSize};
  line-height: ${({ size }) => (headingSizes[size] as { lineHeight?: string })?.lineHeight};
  @media (max-width: 1235px) {
    font-size: ${({ size }) => (headingSizes[size] as { fontSizeMobile?: string })?.fontSizeMobile};
    line-height: ${({ size }) => (headingSizes[size] as { lineHeightMobile?: string })?.lineHeightMobile};
  }
`;

export const Paragraph = styled(Inter)<{
  size: textVariant;
  color?: string;
  fontWeight?: fontWeight;
  component?: string;
}>`
  color: ${({ color }) => (color ? color : colorTokens.textDefault)};
  font-weight: ${({ fontWeight }) => fontWeights[(fontWeight || 'regular') as fontWeight]};
  font-size: ${({ size }) => textSizes[size]?.fontSize};
  line-height: ${({ size }) => textSizes[size]?.lineHeight};
  @media (max-width: 1235px) {
    font-size: ${({ size }) => textSizes[size]?.fontSizeMobile};
    line-height: ${({ size }) => textSizes[size]?.lineHeightMobile};
  }
`;

export const Label = styled(Paragraph)<{ color?: string }>`
  font-weight: ${fontWeights.medium};
`;

export const Overline = styled(Paragraph)<{ color?: string; size?: overlineVariant }>`
  font-weight: ${fontWeights.medium};
  letter-spacing: ${({ size }) => (size === 'sm' || size === 'xs' ? '0.7px' : '0.8px')};
  font-size: ${({ size }) => overlineSizes[size]?.fontSize};
  line-height: ${({ size }) => overlineSizes[size]?.lineHeight};
  color: ${({ color }) => (color ? color : colorTokens.textSubtle)};
`;

export const Icon = styled(Paragraph)<{ color?: string; size?: overlineVariant, fontWeight?: fontWeight, shadow?: string }>`
  font-family: Poppins;
  font-weight: ${({ fontWeight }) => fontWeights[(fontWeight || 'regular') as fontWeight]};
  letter-spacing: ${({ size }) => (iconSizes[size]?.letterSpacing ?? '0.8px')};
  font-size: ${({ size }) => iconSizes[size]?.fontSize};
  line-height: ${({ size }) => iconSizes[size]?.lineHeight};
  color: ${({ color }) => (color ? color : colorTokens.textSubtle)};
  text-shadow: ${({ shadow }) => (shadow ?? 'none')};
`;

// examples for how to use

// <Paragraph size="4xl" color="red" fontWeight={'semibold'}>
//   Text
// </Paragraph>;

// <Heading size="3xl" color="red">
//   Heading
// </Heading>;

// <Label size="2xl" color="red">
//   Label
// </Label>;

// <Overline size="md" color="red">
//   Overline
// </Overline>;